import React from 'react';
import { Facet } from '../models';
import { useSearchEngine } from '../../shared/hooks';

interface Props {
  defaultText: string;
  defaultSelectedOptionValue: string;
  itemsId: string;
  itemsName: string;
  isLoading: boolean;
  facets: Facet[];
  isPost: boolean;
  selectOptionRef: React.RefObject<HTMLSelectElement>;
}

export const Dropdown: React.VFC<Props> = ({
  defaultText,
  defaultSelectedOptionValue,
  itemsId,
  itemsName,
  isLoading,
  facets,
  isPost,
  selectOptionRef,
}) => {
  /*********************************
   * Variables
   *********************************/
  const [doSearch] = useSearchEngine('FilterJobSearchDocument');

  const index = 0;
  const selectedFilter = facets.find((x) => x.selected);
  let selectedFilterValue: string = selectedFilter !== undefined ? selectedFilter.query : '';

  if (!isPost && defaultSelectedOptionValue !== undefined && defaultSelectedOptionValue !== '') {
    selectedFilterValue = defaultSelectedOptionValue;
  }

  /*********************************
   * Functions
   *********************************/

  function onDropdownChange(e) {
    const options = e.target.options;
    const selectedOption = options[options.selectedIndex];
    let query = selectedOption.value;
    const otherFilter = facets[0].query.replace(new RegExp(`&?${itemsName}=[^&]*`, 'g'), ''); // Regex som exkluderar filtret för drop down listan som visas

    // Om man väljer standard värdet
    if (query == '') {
      query = otherFilter;
    }
    if (isPost) doSearch(query + '&isPost=true');
  }

  /*********************************
   * Render
   *********************************/
  return (
    <select
      id={itemsId}
      ref={selectOptionRef}
      name={itemsName}
      disabled={isLoading}
      defaultValue={selectedFilterValue}
      onChange={(e) => onDropdownChange(e)}>
      <option value={''}>{defaultText + ' (Alla)'}</option>
      {facets.map((x) => {
        return (
          <option key={`option_${x.displayName + index}`} value={x.query}>
            {x.displayName + ' ' + '(' + x.count + ')'}
          </option>
        );
      })}
    </select>
  );
};
