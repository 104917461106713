import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorMessage, Facet, SearchResponse, SearchResult } from '../shared.models';
import { RootState } from './shared.store';

// The model of our shared state
interface SharedState<TDocumentType = unknown> {
  selectedCheckboxTrees: { id: string; selectedFacets: Facet[] }[];
  activeFilters: Facet[];
  searchResult: SearchResponse<TDocumentType>;
  searchResponse: SearchResult<TDocumentType>
  isLoading: boolean;
  searchesPerformedCount: number;
  errorMessage: ErrorMessage;
}

// The 'initial state' of our shared state
const initialState: SharedState = {
  selectedCheckboxTrees: [],
  activeFilters: [],
  searchResult: null,
  searchResponse:null,
  isLoading: false,
  searchesPerformedCount: 0,
  errorMessage: null,
};
let endPoint = '';
export const SetEndpoint = (endpoint: string) =>{
endPoint = endpoint;
}

export const fetchSearchData = createAsyncThunk('fetchSearchData', async (query: string,  { rejectWithValue }) => {
  const path = window.location.origin + window.location.pathname;
  const response = await fetch(`${path}/${endPoint}?${query}`);
  if (response.ok) return response.json();
  return rejectWithValue({ status: response.status, text: await response.text() });
});

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setSelectedCheckboxTree: (state, action: PayloadAction<{ id: string; selectedFacets: Facet[] }>) => {
      const index = state.selectedCheckboxTrees.findIndex((x) => x.id === action.payload.id);
      if (index === -1) state.selectedCheckboxTrees.push(action.payload);
      else state.selectedCheckboxTrees[index].selectedFacets = action.payload.selectedFacets;

      let combined: Facet[] = [];
      state.selectedCheckboxTrees.forEach((facet) => {
        combined = combined.concat(facet.selectedFacets);
      });

      state.activeFilters = combined;
    },
    clearActiveFilters: (state) => {
      state.selectedCheckboxTrees = [];
      state.activeFilters = [];
    },
    setInitialSearchResult: (state,  action: PayloadAction<SearchResponse<unknown>>) => {
      state.searchResult = action.payload;
      console.log(state.searchResult);
    },
    setInitialSearchResponse: (state , action: PayloadAction<SearchResult<unknown>>) => {
      state.searchResponse = action.payload;
      console.log(state.searchResult);
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSearchData.rejected, (state, action) => {
      state.isLoading = false;
      const status = (action.payload as { status: number }).status;
      let title = 'Ett fel har inträffat';
      let body =
        'Ett fel inträffade i söktjänstens kommunikation med bakomliggande tjänster. Prova att ladda om sidan och försöka igen, om felet kvarstår kontakta VGR.';
      switch (status) {
        case 404: {
          title = 'Sökresultatet kan tyvärr inte visas';
          body =
            'Vi kan tyvärr inte visa resultatet på din senaste filtrering på grund av tekniska begränsningar. Söktjänsten kan inte hantera för många filter samtidigt. Prova att minska antalet filter och försök igen.';
        }
      }
      state.errorMessage = { status, title, body };
      console.error(action);
    });
    builder.addCase(fetchSearchData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResult = action.payload;
      state.searchResponse = action.payload;
      state.searchesPerformedCount++;
      console.log(state.searchResult);
    });
    
  },
});

export const {
  setSelectedCheckboxTree,
  clearActiveFilters,
  setInitialSearchResult,
  setInitialSearchResponse,
  clearErrorMessage,
} = sharedSlice.actions;

// Selectors
export const selectActiveFilters = (state: RootState) => state.shared.activeFilters;
export const selectIsLoading = (state: RootState) => state.shared.isLoading;
export const selectSearchResult = (state: RootState) => state.shared.searchResult;
export const selectSearchResponse = (state: RootState) => state.shared.searchResponse;
export const selectSearchesPerformedCount = (state: RootState) => state.shared.searchesPerformedCount;
export const selectErrorMessage = (state: RootState) => state.shared.errorMessage;
