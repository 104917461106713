import React, { useEffect, useRef, useState } from 'react';
import { useSearchEngine } from '../hooks';
import { Facet } from '../shared.models';
import { selectIsLoading, selectSearchesPerformedCount } from '../store/shared.reducer';
import { useAppSelector } from '../store/store.hooks';
import { Popper } from './Popper';

interface Props {
  config: Facet;
}
export const ToggleList: React.VFC<Props> = ({ config }) => {
  /*********************************
   * Variables
   *********************************/
  const { displayName, filters } = config;
  const ref = useRef<HTMLButtonElement>(null);

  const getHelpTextHeading = (id: string) => {
    switch (id) {
      case 'lokala':
        return 'Lokala dokument';
      case 'overgripande':
        return 'Övergripande dokument';
      case 'regionovergripande':
        return 'Regionövergripande dokument';
      case 'vardhandboken':
        return 'Vårdhandboken';
      default:
        return '';
    }
  };

  /*********************************
   * Render
   *********************************/
  return (
    <form method="get" className="vgr-togglelist">
      <div className="vgr-togglelist__heading">{displayName}</div>
      <ul>
        {filters.map((facet) => (
          <Item key={`includes_facet_${facet.id}`} facet={facet} helpTextHeading={getHelpTextHeading(facet.id)} />
        ))}
      </ul>

      <noscript className="vgr-searchservice__apply-includes-filter">
        <button ref={ref} className="vgr-searchservice__button" type="submit">
          Applicera filter
        </button>
      </noscript>
    </form>
  );
};

const Item = (props: { facet: Facet; helpTextHeading: string }) => {
  /*********************************
   * Variables
   *********************************/
  const { facet, helpTextHeading } = props;
  const isLoading = useAppSelector(selectIsLoading);
  const searchesPerformedCount = useAppSelector(selectSearchesPerformedCount);
  const [doSearch] = useSearchEngine('FilterStyrandeDokument');
  const ref = useRef<HTMLInputElement>(null);
  const helpButtonRef = useRef<HTMLButtonElement>(null);
  const [showHelp, setShowHelp] = useState(false);

  /*********************************
   * Functions
   *********************************/
  const onChange = (value: string) => doSearch(value);

  const checkboxKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      ref.current.click();
    }
  };

  // Sync server response with local state
  useEffect(() => {
    const isCurrentlySelected = ref.current.checked;
    if (isCurrentlySelected !== facet.selected) {
      console.warn('ToggleList mismatch detected - re-syncing with server data');
      ref.current.checked = facet.selected;
    }
  }, [searchesPerformedCount, facet]);

  /*********************************
   * Render
   *********************************/
  return (
    <li>
      <div className="vgr-togglelist__textwrapper">
        <div>
          {facet.displayName} ({facet.count}){' '}
          <button
            onClick={() => setShowHelp(!showHelp)}
            ref={helpButtonRef}
            type="button"
            className="vgr-togglelist__helpbtn"
            aria-label={`Vad är ${facet.displayName}? Aktivera för mer information`}>
            {helpIcon}
          </button>
        </div>
        <div className="vgr-togglelist__subtext">{facet.subtitle}</div>
      </div>
      <Popper
        show={showHelp}
        setShow={setShowHelp}
        anchor={helpButtonRef}
        content={facet.helpText}
        helpTextHeading={helpTextHeading}
      />
      <label
        className="vgr-switch"
        onKeyDown={checkboxKeyDown}
        tabIndex={0}
        role="checkbox"
        aria-checked={ref.current?.checked}>
        <span className="sr-only">
          {facet.displayName}, {facet.count} dokument
        </span>
        <input
          ref={ref}
          disabled={isLoading}
          type="checkbox"
          tabIndex={-1}
          defaultChecked={facet.selected}
          name={facet.displayName}
          value={facet.query}
          onChange={() => onChange(facet.query)}
        />
        <span className="vgr-switch__ui"></span>
      </label>
    </li>
  );
};

const helpIcon = (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.49999C6.00019 5.48295 6.76364 4.628 7.77418 4.51317C8.78472 4.39833 9.72053 5.06018 9.94892 6.05125C10.1773 7.04232 9.62555 8.04698 8.66667 8.38599C8.26694 8.52732 7.99978 8.90535 8 9.32933V9.99999"
      stroke="#005C95"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12C7.86193 12 7.75 12.1119 7.75 12.25C7.75 12.3881 7.86193 12.5 8 12.5C8.13807 12.5 8.25 12.3881 8.25 12.25C8.25 12.1119 8.13807 12 8 12V12"
      stroke="#005C95"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z"
      stroke="#005C95"
    />
  </svg>
);
