import React from 'react';
import Select, { SelectInstance } from 'react-select';
import { ActionMeta, GroupBase } from 'react-select/dist/declarations/src/types';
import { swedishAriaLiveMessages } from '../../shared/swedishAriaLiveMessages';
import { useSearchEngine } from '../../shared/hooks';
import { SelectListItem } from '../models';

export interface IOption {
  label: string;
  value: string;
}

interface Props {
  itemsId: string;
  itemsName: string;
  isLoading: boolean;
  endPoint: string;
  isPost: boolean;
  filterKey: string;
  municipalityList: SelectListItem[];
  removeEmptyParams: (string) => string;
  multiSelectRef: React.RefObject<SelectInstance<IOption, true, GroupBase<IOption>>>;
}
export const MultiSelect: React.VFC<Props> = ({
  itemsId,
  itemsName,
  isLoading,
  endPoint,
  filterKey,
  isPost,
  municipalityList,
  removeEmptyParams,
  multiSelectRef,
}) => {
  type Option = { label: string; value: string };

  const [doSearch] = useSearchEngine(endPoint);

  const availableOptions =
    municipalityList.length > 0
      ? (municipalityList.map((facet) => ({
          value: facet.Value,
          label: facet.Text,
        })) as Option[])
      : [];

  const selectOptions =
    municipalityList.length > 0
      ? (municipalityList
          .filter((x) => x.Selected)
          .map((facet) => ({
            value: facet.Value,
            label: facet.Text,
          })) as Option[])
      : [];

  const selectOnChange = (options: readonly Option[], actionMeta: ActionMeta<Option>) => {
    let query = '';
    // Skapa en instans av URLSearchParams med befintliga sökparametrar
    const queryParams = new URLSearchParams(window.location.search);

    // Kontrollera om åtgärden är 'clear'
    if (actionMeta.action === 'clear') {
      queryParams.delete(itemsId); // Ta bort nyckeln om villkoret är uppfyllt

      query = queryParams.toString();
    } else if (actionMeta.action === 'remove-value') {
      // Kontrollera om filterValue finns och innehåller det värde du vill ta bort (actionMeta.removedValue.value)
      try {
        // Om det inte finns några items kvar, ta bort hela filter5 från sökparametrarna
        if (options.length === 0) {
          queryParams.delete(itemsId);
        } else {
          // Uppdatera filter5 med de uppdaterade värdena
          const values: string[] = [];
          options.map((x) => values.push(x.value));

          queryParams.set(itemsId, values.join(','));
        }

        query = queryParams.toString();
      } catch (error) {
        console.error('Ett fel inträffade vid skapandet av URLSearchParams:', error);
      }
    } else {
      // när man lägger till nya item
      queryParams.delete(itemsId);
      const existingFilters = Array.from(queryParams.entries()).filter(([key]) => key === 'filter');
      const matchingMuniciplityFilter = existingFilters.find(([, value]) => value.startsWith(filterKey));
      if (matchingMuniciplityFilter != null || matchingMuniciplityFilter != undefined)
        queryParams.delete('filter', matchingMuniciplityFilter[1]); // ta bort matchingMuniciplityFilter för att inte få dubletter
      if (options.length > 0) {
        let componentsQueryString = '';
        componentsQueryString = filterKey;
        const newOptions = [];
        options.forEach((element, index) => {
          if (index === 0) componentsQueryString += element.value;
          else componentsQueryString += ',' + element.value;
          newOptions.push(element);
        });

        query =
          queryParams != undefined
            ? queryParams.toString() + '&' + itemsId + '=' + componentsQueryString
            : itemsId + '=' + componentsQueryString;
      } else query = queryParams.toString();
    }

    query = removeEmptyParams(query);
    if (isPost) doSearch(query);
  };

  return (
    <Select
      ref={multiSelectRef}
      isMulti={true}
      id={itemsId}
      closeMenuOnSelect={false}
      name={itemsName}
      options={availableOptions}
      onChange={selectOnChange}
      placeholder={'Kommun (Alla)'}
      defaultValue={selectOptions}
      ariaLiveMessages={swedishAriaLiveMessages}
      isDisabled={isLoading}
      className="multi-select"
      classNames={{
        control: (state) =>
          state.isFocused ? 'multi-select__control multi-select__control--focus' : 'multi-select__control',
        menuList: (state) => 'multi-select__menu-list',
        option: (state) => 'multi-select__option',
        indicatorsContainer: (state) => 'multi-select__indicators-container',
        multiValue: (state) => 'multi-select__multi-value',
        multiValueLabel: (state) => 'multi-select__multi-value-label',
        multiValueRemove: (state) =>
          state.isFocused
            ? 'multi-select__multi-value-remove multi-select__multi-value-remove--focus'
            : 'multi-select__multi-value-remove',
        clearIndicator: (state) => 'multi-select__clear-indicator',
        placeholder: (state) => 'multi-select__placeholder',
      }}
    />
  );
};
