import React from 'react';
import { useSearchEngine } from '../hooks';
import { clearActiveFilters, selectActiveFilters, selectIsLoading, selectSearchResult } from '../store/shared.reducer';
import { useAppDispatch, useAppSelector } from '../store/store.hooks';

export const ActiveSearchFilters: React.VFC = () => {
  /*********************************
   * Variables
   *********************************/
  const [doSearch] = useSearchEngine('FilterStyrandeDokument');
  const isLoading = useAppSelector(selectIsLoading);
  const activeFilters = useAppSelector(selectActiveFilters);
  const dispatch = useAppDispatch();
  const searchResult = useAppSelector(selectSearchResult);

  /*********************************
   * Functions
   *********************************/
  function clearAllFilters() {
    dispatch(clearActiveFilters());
    doSearch(searchResult.query.resetQuery);
  }

  const removeFilter = (id: string) => {
    // Grab the correct checkbox from DOM and click it to trigger a correct search
    const checkbox = document.getElementById(id) as HTMLInputElement;
    checkbox.click();
  };

  /*********************************
   * Render
   *********************************/
  return (
    <div className="vgr-searchservice__active-filters">
      {activeFilters.length === 0 && <div>Inga aktiva filter</div>}
      {activeFilters.length > 0 && (
        <>
          <div>Aktiva filter:</div>
          <ul className="vgr-settings-group__choices-inner">
            {activeFilters.map((filter) => (
              <li key={filter.query}>
                <div className="vgr-settings-group__pill">
                  <span className="vgr-settings-group__pill-label">{filter.displayName}</span>
                  <button
                    disabled={isLoading}
                    onClick={() => removeFilter(filter.id)}
                    className="vgr-settings-group__pill-icon">
                    <span className="sr-only">Ta bort valt filter: {filter.displayName}</span>
                    {closeIcon}
                  </button>
                </div>
              </li>
            ))}
            <li className="vgr-searchservice__clear-filters">
              <button
                disabled={isLoading}
                className="vgr-searchservice__linkbutton"
                onClick={() => clearAllFilters()}
                type="button">
                Rensa alla filter
              </button>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

const closeIcon = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.879883 12.1245L12.1299 0.874512"
      stroke="#494746"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1299 12.1245L0.879883 0.874512"
      stroke="#494746"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
