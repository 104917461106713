import React, { useState } from 'react';
import { NavigationItemProps } from './types';
import { MobileMainMenuProps, MenuItem } from '../MobileMainMenu/types';

export const NavigationItem: React.VFC<NavigationItemProps> = ({
  menuItem,
  parentIsExpanded,
  isRootItem,
  isLastChildOfRootItem,
  isVeryLastListItem,
  chevronIconSvg,
}) => {
  const [id, setId] = useState(Math.random().toString(16).slice(2));
  const [currentMenuItem, setCurrentMenuItem] = useState(menuItem);
  const [isExpanded, setIsExpanded] = useState(menuItem.isInActiveChain && menuItem.hasChildren);
  const [hasPreloadedItems, setHasPreloadedItems] = useState(menuItem.isInActiveChain);
  let isLoadingPages = false;

  async function onExpandNavigationClick(event, clickedItem: MenuItem) {
    event.preventDefault();

    // Om menyalternativet redan är öppnat ska vi bara minimera menyn.
    if (isExpanded) {
      setIsExpanded((current) => !current);
      return;
    }

    // Vi behöver inte hämta fler meny alternativ om de redan finns på objektet.
    if (hasPreloadedItems) {
      setIsExpanded((current) => !current);
      return;
    }

    if (isLoadingPages) {
      console.log('Hämtar menyalternativ...');
      return;
    }

    isLoadingPages = true;

    await fetch(
      'fetchmenuitems?' +
        new URLSearchParams({
          pageId: clickedItem.pageId.toString(),
          currentPageId: clickedItem.pageId.toString(),
        })
    )
      .then((response) => response.json())
      .then((nextMenuItem: MobileMainMenuProps) => {
        let updatedMenuItem = currentMenuItem;
        updatedMenuItem.children = nextMenuItem.menuItems;
        setCurrentMenuItem(updatedMenuItem);

        setIsExpanded((current) => !current);
        setHasPreloadedItems(true);

        isLoadingPages = false;
      })
      .catch((error: Error) => {
        isLoadingPages = false;
        console.error('Error:', error);
      });
  }

  const showChildren = currentMenuItem.hasChildren && hasPreloadedItems;

  let wrapperCssClass = '';

  if (currentMenuItem.isCurrentPage) {
    wrapperCssClass = 'secondary-navigation__selected-menu-item-wrapper';

    if ((!isExpanded || !currentMenuItem.hasChildren) && isRootItem) {
      wrapperCssClass += ' secondary-navigation__selected-menu-item-wrapper--root-collapsed';
    } else if (isRootItem) {
      wrapperCssClass += ' secondary-navigation__selected-menu-item-wrapper--active-root';
    } else if (isExpanded && !isRootItem) {
      wrapperCssClass += ' secondary-navigation__selected-menu-item-wrapper--expanded-self';
    } else if (isLastChildOfRootItem) {
      wrapperCssClass += ' secondary-navigation__selected-menu-item-wrapper--last-of-active-root';
    } else if (isVeryLastListItem) {
      wrapperCssClass += ' secondary-navigation__selected-menu-item-wrapper--very-last-list-item';
    }
  }

  return (
    <li>
      <div className={wrapperCssClass}>
        <div
          className={`secondary-navigation__menu-item 
        ${isExpanded ? 'secondary-navigation__menu-item--expanded-self' : ''}
        ${isExpanded || parentIsExpanded ? 'secondary-navigation__menu-item--expanded-level' : ''}
        ${currentMenuItem.isInActiveChain ? 'secondary-navigation__menu-item--active-chain' : ''}
        ${isExpanded && isRootItem ? 'secondary-navigation__menu-item--active-root' : ''}
        ${
          (isExpanded || parentIsExpanded) && isLastChildOfRootItem
            ? 'secondary-navigation__menu-item--last-of-active-root'
            : ''
        } 
        ${currentMenuItem.isCurrentPage ? 'secondary-navigation__menu-item--selected' : ''}`}>
          <a href={currentMenuItem.href} className={currentMenuItem.isCurrentPage ? 'ignore-link-focus' : ''}>
            {currentMenuItem.text}
          </a>
          {currentMenuItem.hasChildren && (
            <button
              aria-expanded={isExpanded}
              aria-label={`Visa undersidor till ${currentMenuItem.text}`}
              aria-controls={id}
              onClick={(e) => {
                onExpandNavigationClick(e, currentMenuItem);
              }}
              className={`link-focus secondary-navigation__expand-button ${
                currentMenuItem.isCurrentPage ? 'dark' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: chevronIconSvg }}
            />
          )}
        </div>
      </div>
      <div id={id} className={`secondary-navigation__child-container ${isExpanded ? '' : 'd-none'}`}>
        {showChildren && (
          <ul key={id}>
            {currentMenuItem.children.map((item: MenuItem, index: number) => (
              <NavigationItem
                key={item.pageId}
                menuItem={item}
                parentIsExpanded={isExpanded}
                isRootItem={false}
                isLastChildOfRootItem={isRootItem && index === currentMenuItem.children.length - 1}
                isVeryLastListItem={
                  (isLastChildOfRootItem || isVeryLastListItem) && // Första iterationen kan `isLastChildOfRootItem` vara true, vilket kommer göra `isVeryLastListItem` true i nästa iteration
                  !item.hasChildren &&
                  index === currentMenuItem.children.length - 1
                }
                chevronIconSvg={chevronIconSvg}
              />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};
