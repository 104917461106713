import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Facet } from '../../shared.models';
import { Tree } from './components/Tree';
import cloneDeep from 'lodash.clonedeep';
import { findValueDeep, eachDeep } from 'deepdash-es/standalone';
import { useSearchEngine } from '../../hooks';
import { getSelectedItems, mutateTreeItems, recurseSetIndeterminate } from './checkboxtree.utils';
import { useAppDispatch } from '../../store/store.hooks';
import { setSelectedCheckboxTree } from '../../store/shared.reducer';

interface Props {
  treeId: string;
  tight: boolean;
  heading: string;
  items: Facet[];
  maxTopLevelItems?: number;
}

export const CheckboxTree: React.VFC<Props> = ({ treeId, tight, heading, items, maxTopLevelItems }) => {
  const [doSearch] = useSearchEngine('FilterStyrandeDokument');
  const dispatch = useAppDispatch();
  const [mutatedTreeItems] = useMemo(() => mutateTreeItems(items), [items]);
  const [treeItems, setTreeItems] = useState(mutatedTreeItems);

  let treeClass = ' vgr-checkbox-tree';
  if (tight) treeClass += treeClass + '--tight';

  // Sync server response with local state
  useEffect(() => {
    setTreeItems(mutatedTreeItems);
  }, [mutatedTreeItems]);

  // This function gets called by component "Item" when a checkbox is clicked or activated by keyboard
  const checkboxInteracted = useCallback(
    (item: Facet) => {
      const treeClone = cloneDeep(treeItems);
      const itemClone = findValueDeep(treeClone, (x) => x.id === item.id, { childrenPath: ['children'] }) as Facet;

      // Special handling when clicking on a box that is indeterminate.
      // The box should stay selected false, indeterminate should be removed and all its decendands should be deselected.
      if (itemClone._indeterminate) {
        itemClone.selected = false;
        itemClone._indeterminate = false;
        // Loop downwards from the item all the way down to the leaves
        eachDeep(
          itemClone,
          (value: Facet) => {
            value._indeterminate = false;
            value.selected = false;
          },
          { childrenPath: ['children'], includeRoot: false }
        );
      }

      // Standard handling, simply flip the selected value
      else itemClone.selected = !itemClone.selected;

      // Grab the root node of our item
      const rootId = itemClone._rootId || itemClone.id;
      const root = treeClone.find((x) => x.id === rootId);
      // Need to re-analyze the entire current tree for indeterminate logic
      recurseSetIndeterminate([root]);

      // Get currently selected items and update the active filters
      const selectedItems = getSelectedItems(treeClone);
      dispatch(setSelectedCheckboxTree({ id: treeId, selectedFacets: selectedItems }));

      // Set new state so that the changes will trigger updates to components further down the component tree
      setTreeItems(treeClone);

      // Fire search query to search engine
      doSearch(itemClone.query);
    },
    [dispatch, treeItems, doSearch, treeId]
  );

  return (
    <div id={treeId} className={treeClass}>
      <div className="vgr-filter-tree__heading">{heading}</div>
      <Tree
        treeId={treeId}
        maxTopLevelItems={maxTopLevelItems}
        items={treeItems}
        checkboxInteracted={checkboxInteracted}
      />
    </div>
  );
};
