export interface StyrandeDokument {
  id: string;
  title: string;
  clickUrl: string;
  author: string[];
  validFrom: string;
  validTo: string;
  objectType: string;
  description: string;
  descriptionVardhandboken: string;
  category: string[];
  forUnit: string[];
  publishedForUnitWithHierarchy: string[];
  validityArea: string[];
  contentResponsible: string[];
  approvedBy: string[];

  revisionDate?: string;
  url?: string;
  source?: string;

  subjectClassification: string[];
  tag: string[];
  sweMeSHCode: string[];
  hsaVerksamhetsKod: string[];
  subjectLocalClassification: string[];
  producer: string;
  classificationCode: string;
  classificationName: string;
  sourceId: string;
  sourceVersion: string;
  siteInfoName: string[];

  isNewDocument: boolean;
  styrandedokumentResultType: ResultType;
}


export enum ResultType {
  'Lokalt',
  'Övergripande',
  'Regionövergripande',
  'Vårdhandboken'
}