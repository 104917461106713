import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { KeyMatchItem } from './KeyMatchItem';
import { Keymatch, KeyMatchListProps } from './types';

export const KeyMatchList = (props: KeyMatchListProps) => {
  const [pageNumber, setPageNumber] = useState(0);

  const { numberOfItemsToShow } = props;
  const [sortState, setSortState] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [keyMatchesPerPage, setKeyMatchesPerPage] = useState(props.keyMatches);
  const [allKeyMatches, setAllKeyMatches] = useState(props.keyMatches);
  const [searchabelList, setSearchableList] = useState(props.keyMatches);
  const [searchTerm, setSearchTerm] = useState('');
  const pagesVisited = pageNumber * numberOfItemsToShow;

  useEffect(() => {
    updateKeyMatchesPerPage(allKeyMatches, pagesVisited);
    // setallKeyMatches(allKeyMatches);
    setPageCount(Math.ceil(allKeyMatches.length / numberOfItemsToShow));
  }, []);

  const search = (searchTerm: string, allKeyMatches: Keymatch[]) => {
    const searchList = allKeyMatches.filter((keyMatch) => {
      if (searchTerm === '') {
        return keyMatch;
      } else if (keyMatch.Titel.toLowerCase().includes(searchTerm.toLowerCase())) {
        return keyMatch;
      } else if (keyMatch.Email.toLowerCase().includes(searchTerm.toLowerCase())) return keyMatch;
      else if (keyMatch.Scope !== null && keyMatch.Scope.toLowerCase().includes(searchTerm.toLowerCase()))
        return keyMatch;
      else if (keyMatch.Terms.toLowerCase().includes(searchTerm.toLowerCase())) return keyMatch;

      return false;
    });
    //  setallKeyMatches(searchList);
    setSearchableList(searchList);
    updateKeyMatchesPerPage(searchList, pageNumber);
    setPageCount(Math.ceil(searchList.length / numberOfItemsToShow));
  };

  const deleteItem = (id: string) => {
    const updatedList = allKeyMatches.filter((x) => x.Id.toString() !== id);
    const updatedSearchableList = searchabelList.filter((x) => x.Id.toString() !== id);
    const newList = updatedSearchableList.length > 0 ? updatedSearchableList : updatedList;
    setAllKeyMatches(updatedList);
    updateKeyMatchesPerPage(newList, pageNumber);

    setSearchableList(newList);

    // updatePageCount(allKeyMatches);
    setPageCount(Math.ceil(newList.length / numberOfItemsToShow));
    fetch(`/KeyMatchListingPage/Delete?isIframe=false&keymatchId=${id.toString()}`, {
      method: 'post',
    })
      .then(function (response) {
        if (response.status !== 200) {
          alert(`Något gick fel, vänligen ladda om sidan. Svaret returnerade statuskod: ${response.status}`);
        } else if (response.status == 200) {
          console.log('fetch returned ok');
        }
      })
      .catch(function (err) {
        alert(`Något gick fel, vänligen ladda om sidan. Error: ${err}`);
      });
  };
  const updateKeyMatchesPerPage = (updatedList, selected) => {
    setKeyMatchesPerPage(
      updatedList.slice(selected * numberOfItemsToShow, selected * numberOfItemsToShow + numberOfItemsToShow)
    );
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
    setPageCount(Math.ceil(searchabelList.length / numberOfItemsToShow));
    updateKeyMatchesPerPage(searchabelList, selected);
  };
  const sortListByDateTime = (key, sortState, allKeyMatches, nulls) => {
    const evaluate = (a, b) => (sortState === 'Ascending' ? a - b : b - a);

    if (key !== '') {
      const sortedList = allKeyMatches.sort((a, b) => {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        return evaluate(dateA, dateB);
      });
      return sortedList.concat(nulls);
    }
  };
  const sortListByBoolean = (key, sortState, allKeyMatches) => {
    if (sortState == 'Ascending') {
      allKeyMatches.sort((a, b) => {
        return a[key] === b[key] ? 0 : a[key] ? -1 : 1;
      });
    } else {
      allKeyMatches.sort((a, b) => b[key] - a[key]).reverse();
    }
    return allKeyMatches;
  };
  const sortListByString = (key, sortState, allKeyMatches) => {
    if (key !== '' && sortState == 'Ascending') {
      return allKeyMatches.sort((a, b) => a[key].toString().localeCompare(b[key].toString()));
    } else {
      return allKeyMatches.sort((a, b) => b[key].toString().localeCompare(a[key].toString()));
    }
  };
  const sortList = (key, sortList) => {
    if (sortState == 'Ascending') setSortState('Descending');
    else setSortState('Ascending');
    let sortedList;
    let ickeNullabelList;
    let nulls;
    if (key == 'created_at' || key == 'updated_at') {
      if (key == 'created_at') {
        ickeNullabelList = sortList.filter((x) => x.created_at !== '');
        nulls = sortList.filter((x) => x.created_at === '');
      } else {
        ickeNullabelList = sortList.filter((x) => x.updated_at !== '');
        nulls = sortList.filter((x) => x.updated_at === '');
      }

      sortedList = sortListByDateTime(key, sortState, ickeNullabelList, nulls);
    } else if (key == 'Active') {
      sortedList = sortListByBoolean(key, sortState, sortList);
    } else {
      sortedList = sortListByString(key, sortState, sortList);
    }

    updateKeyMatchesPerPage(sortedList, 0);
    setPageCount(Math.ceil(sortedList.length / numberOfItemsToShow));
    setSearchableList(sortedList);
  };
  return (
    <div id="key-matches-list">
      <div className="key-matches-list-search">
        <input
          type="search"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handlePageChange;
          }}
          className="input"
          placeholder="Sök på title, e-post, scope eller nyckelord"
        />
        <button type="submit" id="search-button" onClick={(e) => search(searchTerm, allKeyMatches)}></button>
      </div>
      <table className="hor-zebra key-matches-list">
        <thead>
          <tr>
            <th>
              <button onClick={(e) => sortList('Titel', searchabelList)}>Titel</button>
            </th>
            <th>
              {' '}
              <button onClick={(e) => sortList('Scope', searchabelList)}>Scopes</button>{' '}
            </th>
            <th>
              {' '}
              <button onClick={(e) => sortList('Active', searchabelList)}>Aktiv</button>{' '}
            </th>
            <th>
              <button>Beskrivning</button>{' '}
            </th>
            <th>
              <button onClick={(e) => sortList('Terms', searchabelList)}>Nyckelord</button>{' '}
            </th>
            <th>
              <button onClick={(e) => sortList('created_at', searchabelList)}>Skapad datum</button>{' '}
            </th>
            <th>
              <button onClick={(e) => sortList('updated_at', searchabelList)}>Uppdaterings datum</button>{' '}
            </th>
            <th>
              <button onClick={(e) => sortList('Email', searchabelList)}>E-post</button>{' '}
            </th>
            <th>
              {' '}
              <button onClick={(e) => sortList('Url', searchabelList)}>Länk</button>{' '}
            </th>
            {(props.UserIsKeyMatchSuperAdmin ||
              (props.ScopesUserMayEdit != null && props.ScopesUserMayEdit.length > 0)) && (
              <>
                <th> Redigera</th>
                <th>Ändra status</th>
                <th>Ta bort</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {keyMatchesPerPage.map((keyMatch) => {
            return (
              <KeyMatchItem
                key={keyMatch.Id}
                KeyMatch={keyMatch}
                UserIsKeyMatchSuperAdmin={props.UserIsKeyMatchSuperAdmin}
                ScopesUserMayEdit={props.ScopesUserMayEdit}
                deleteCallback={deleteItem}
              />
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <ReactPaginate
                nextLabel="Nästa"
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="Föregående"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
