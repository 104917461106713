import React, { useEffect, useRef } from 'react';
import {
  clearActiveFilters,
  clearErrorMessage,
  selectErrorMessage,
  selectSearchResult,
  setSelectedCheckboxTree,
} from '../store/shared.reducer';
import { useAppDispatch, useAppSelector } from '../store/store.hooks';
import Modal from 'react-modal';
import { useSearchEngine } from '../hooks';
import { getSelectedItems } from './CheckboxTree/checkboxtree.utils';

export const ErrorHandler: React.VFC = () => {
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(selectErrorMessage);
  const [doSearch] = useSearchEngine('FilterStyrandeDokument');
  const searchResult = useAppSelector(selectSearchResult);
  const appElement = useRef(null);

  const clearAllFilters = () => {
    dispatch(clearErrorMessage());
    dispatch(clearActiveFilters());
    doSearch(searchResult.query.resetQuery);
  };

  const dismissErrorMessage = () => {
    dispatch(clearErrorMessage());
    doSearch(searchResult.query.parameters);
    dispatch(
      setSelectedCheckboxTree({
        id: searchResult.facet.id,
        selectedFacets: getSelectedItems(searchResult.facet.children),
      })
    );
    dispatch(
      setSelectedCheckboxTree({
        id: searchResult.subjectFacets.id,
        selectedFacets: getSelectedItems(searchResult.subjectFacets.filters),
      })
    );
  };

  useEffect(() => {
    appElement.current = document.getElementById('wrapper');
  }, []);

  return (
    <Modal appElement={appElement.current} isOpen={!!errorMessage} onRequestClose={() => dismissErrorMessage()}>
      <div className="wrapper">
        <div className="icon">{icon}</div>
        <div>
          <h2>{errorMessage?.title}</h2>
          <p>{errorMessage?.body}</p>
        </div>
      </div>
      <div className="dialog-footer">
        <button className="outlined" onClick={clearAllFilters} type="button">
          Rensa alla filter
        </button>
        <button className="primary" onClick={dismissErrorMessage} type="button">
          Stäng
        </button>
      </div>
    </Modal>
  );
};

const icon = (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95918 2.36735C2.63232 2.36735 2.36735 2.63232 2.36735 2.95918V20.7143C2.36735 21.0411 2.63232 21.3061 2.95918 21.3061H6.5102C7.16393 21.3061 7.69388 21.8361 7.69388 22.4898V25.449L12.902 21.5429C13.1069 21.3892 13.3561 21.3061 13.6122 21.3061H26.0408C26.3677 21.3061 26.6327 21.0411 26.6327 20.7143V2.95918C26.6327 2.63232 26.3677 2.36735 26.0408 2.36735H2.95918ZM0 2.95918C0 1.32487 1.32487 0 2.95918 0H26.0408C27.6751 0 29 1.32487 29 2.95918V20.7143C29 22.3486 27.6751 23.6735 26.0408 23.6735H14.0068L7.22041 28.7633C6.86173 29.0323 6.38186 29.0755 5.98085 28.875C5.57984 28.6745 5.32653 28.2647 5.32653 27.8163V23.6735H2.95918C1.32487 23.6735 0 22.3486 0 20.7143V2.95918Z"
      fill="#6C4A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7602 17.6071C13.7602 16.7083 14.4889 15.9796 15.3878 15.9796C16.2866 15.9796 17.0153 16.7083 17.0153 17.6071C17.0153 18.506 16.2866 19.2347 15.3878 19.2347C14.4889 19.2347 13.7602 18.506 13.7602 17.6071Z"
      fill="#6C4A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3878 5.32653C16.0415 5.32653 16.5714 5.85648 16.5714 6.5102V13.6122C16.5714 14.266 16.0415 14.7959 15.3878 14.7959C14.734 14.7959 14.2041 14.266 14.2041 13.6122V6.5102C14.2041 5.85648 14.734 5.32653 15.3878 5.32653Z"
      fill="#6C4A00"
    />
  </svg>
);
