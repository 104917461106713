import React, { useEffect, useState } from 'react';
import { CheckBox, MyEnum, OrganisationSearchFilterObject, SelectListItem } from '../models';
import { IOption, MultiSelect } from './MultiSelect';
import { GroupBase, SelectInstance } from 'react-select';

interface Props {
  isLoading: boolean;
  isPost: boolean;
  organisationSearchFilterObject: OrganisationSearchFilterObject;
  checkboxInteracted: (
    item: CheckBox,
    key: number,
    isChecked: boolean,
    index: number,
    ref: React.RefObject<HTMLInputElement>
  ) => void;
  removeEmptyParams: () => string;
  searchParameters: URLSearchParams;
  municipalityList: SelectListItem[];
  opningsTimeRef: React.RefObject<HTMLInputElement>[];
  clinicsTypesRef: React.RefObject<HTMLInputElement>[];
  multiSelectRef: React.RefObject<SelectInstance<IOption, true, GroupBase<IOption>>>;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Filtering: React.VFC<Props> = ({
  isLoading,
  organisationSearchFilterObject,
  checkboxInteracted,
  searchParameters,
  removeEmptyParams,
  municipalityList,
  isPost,
  multiSelectRef,
  opningsTimeRef,
  clinicsTypesRef,
  setForceUpdate,
}) => {
  const [opningsTime, setOpningsTime] = useState(opningsTimeRef);
  const [clinicsTypes, setClinicsTypes] = useState(clinicsTypesRef);
  const allfilterValues = searchParameters.getAll('filter4');
  useEffect(() => {
    setForceUpdate(false);
    setClinicsTypes(clinicsTypesRef);
    setOpningsTime(opningsTimeRef);
  }, [clinicsTypesRef, opningsTimeRef, setForceUpdate]);
  return (
    <fieldset className="">
      <legend className={'visually-hidden'}>Filtrera sökning</legend>
      <div className="grid search-control-block__filtering">
        {organisationSearchFilterObject.HideMunicipality == false && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Välj kommun</legend>
              <MultiSelect
                itemsId="filter5"
                itemsName="filter5"
                municipalityList={municipalityList}
                isLoading={isLoading}
                endPoint={'FilterOrganisationSearchDocument'}
                filterKey={'hsaMunicipalityCode:'}
                removeEmptyParams={removeEmptyParams}
                isPost={isPost}
                multiSelectRef={multiSelectRef}
              />
            </fieldset>
          </div>
        )}
        {organisationSearchFilterObject.HideClinics == false && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Mottagningen har</legend>

              {organisationSearchFilterObject.Clinics.map((clinic, index) => (
                <div className="grid" key={clinic.Value}>
                  <input
                    type="checkbox"
                    name={clinic.Value}
                    id={clinic.Value}
                    disabled={isLoading}
                    onChange={(event) =>
                      checkboxInteracted(
                        clinic,
                        MyEnum.oppeningHours,
                        event.target.checked,
                        index,
                        opningsTimeRef[index]
                      )
                    }
                    value={
                      opningsTime[index] != undefined && opningsTime[index].current != null
                        ? opningsTime[index].current.value?.toString()
                        : clinic.IsChecked?.toString()
                    }
                    checked={
                      opningsTime[index] != undefined && opningsTime[index].current != null
                        ? opningsTime[index].current.checked
                        : clinic.IsChecked
                    }
                    ref={opningsTime[index]}
                  />
                  <label htmlFor={clinic.Value} className="search-control-block__label no-bold">
                    {clinic.Text}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
        )}
        {!organisationSearchFilterObject.HideCareType && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Välj typ av mottagning</legend>

              {organisationSearchFilterObject.CareTypes.map((careType, i) => (
                <div className="grid" key={careType.Value}>
                  <input
                    type="checkbox"
                    name={'filter4'}
                    id={`filter4_${careType.Value}`} // Use a unique identifier based on careType.Value
                    disabled={isLoading}
                    ref={clinicsTypes[i]}
                    onChange={(event) =>
                      checkboxInteracted(careType, MyEnum.clinicTypes, event.target.checked, i, clinicsTypesRef[i])
                    }
                    value={
                      clinicsTypes[i] != undefined && clinicsTypes[i].current != null
                        ? careType.Value
                        : careType.IsChecked ||
                          (allfilterValues != undefined && allfilterValues.find((x) => x.includes(careType.Value)))
                        ? careType.Value
                        : 'false'
                    }
                    checked={
                      clinicsTypes[i] != undefined && clinicsTypes[i].current != null
                        ? clinicsTypes[i].current.checked
                        : careType.IsChecked ||
                          (allfilterValues != undefined &&
                            allfilterValues.length > 0 &&
                            allfilterValues.find((x) => x.includes(careType.Value)) != undefined &&
                            allfilterValues.find((x) => x.includes(careType.Value)).length > 0)
                        ? true
                        : false
                    }
                  />

                  <label htmlFor={`filter4_${careType.Value}`} className="search-control-block__label no-bold">
                    {careType.Text}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
        )}
      </div>
    </fieldset>
  );
};
