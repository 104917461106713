import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { KeyMatchItemProps } from './types';

export const KeyMatchItem = (props: KeyMatchItemProps) => {
  const [isActive, setIsActive] = useState(props.KeyMatch.Active);
  const editKeyMatchUrl = `/sponsrade-sidor/Edit?id=${props.KeyMatch.Id}`;

  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);

  const handleClick = () => {
    const active = !isActive;
    props.KeyMatch.Active = active;
    setIsActive(active);
    fetch(`/KeyMatchListingPage/ChangeActiveState?active=${active.toString()}&id=${props.KeyMatch.Id.toString()}`, {
      method: 'post',
      headers: {
        credentials: 'same-origin',
      },
    })
      .then(function (response) {
        if (response.status !== 200) {
          alert(`Något gick fel, vänligen ladda om sidan. Svaret returnerade statuskod: ${response.status}`);
        } else if (response.status == 200) {
          console.log('fetch returned ok');
        }
      })
      .catch(function (err) {
        alert(`Något gick fel, vänligen ladda om sidan. Error: ${err}`);
      });
  };
  const handleDeleteClick = () => {
    const confirmed = window.confirm('Vill du ta bort den här sponsrade länken?');
    if (confirmed) props.deleteCallback(props.KeyMatch.Id.toString());
  };

  return (
    <tr className="">
      <td>
        <span className="word-wrap">{props.KeyMatch.Titel}</span>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.Scope}</span>
      </td>
      <td className="active">
        <>{}</>
        <div className="word-wrap word-wrap__active">{props.KeyMatch.Active ? 'Ja' : 'Nej'}</div>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.Description}</span>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.Terms}</span>
      </td>
      <td>
        <div className="word-wrap">
          {props.KeyMatch.created_at !== ''
            ? moment(props.KeyMatch.created_at).locale('sv').format('DD MMMM yyyy')
            : ''}
        </div>
      </td>
      <td>
        <div className="word-wrap">
          {props.KeyMatch.updated_at !== ''
            ? moment(props.KeyMatch.updated_at).locale('sv').format('DD MMMM yyyy')
            : ''}
        </div>
      </td>

      <td>
        <span className="word-wrap">{props.KeyMatch.Email}</span>
      </td>
      <td>
        <a href={props.KeyMatch.Url.toString()} className="word-wrap url">
          {props.KeyMatch.Url.toString()}{' '}
        </a>
      </td>
      {(props.UserIsKeyMatchSuperAdmin || (props.ScopesUserMayEdit != null && props.ScopesUserMayEdit.length > 0)) && (
        <>
          <td>
            <a
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.Scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.Scope.replace(' ', '')
                      .split(',')
                      .some((y) => x == y)
                  ))
                  ? ''
                  : 'disabledAnchor'
              }
              href={editKeyMatchUrl}>
              Redigera
            </a>
          </td>
          <td>
            <button
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.Scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.Scope.replace(' ', '')
                      .split(',')
                      .some((y) => x == y)
                  ))
                  ? ''
                  : 'disabledAnchor'
              }
              onClick={handleClick}>
              {isActive ? 'Deaktivera' : 'Aktivera'}
            </button>
          </td>
          <td>
            <button
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.Scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.Scope.replace(' ', '')
                      .split(',')
                      .some((y) => x == y)
                  ))
                  ? ''
                  : 'disabledAnchor'
              }
              onClick={handleDeleteClick}>
              Ta bort
            </button>
          </td>
        </>
      )}
    </tr>
  );
};
