import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { CheckBox, OrganisationSearchFilterObject, MyEnum, ParamKey, FilterKey, SelectListItem } from './models';
import { useSearchEngine } from '../shared/hooks';
import { selectIsLoading } from '../shared/store/shared.reducer';
import { useAppSelector } from '../shared/store/store.hooks';
import { Filtering } from './Components/Filtering';
import { GroupBase, SelectInstance } from 'react-select';
import { IOption } from './Components/MultiSelect';

interface Props {
  crossIconSvg: string;
  chevronIconSvg: string;
  resultPageLinkAsString: string;
  isPost: boolean;
  organisationSearchFilterObject: OrganisationSearchFilterObject;
  municipalityList: SelectListItem[];
  placeHolder: string;
  heading: string;
}
export const OrganisationSearchControlBlock: React.VFC<Props> = ({
  crossIconSvg,
  chevronIconSvg,
  resultPageLinkAsString,
  isPost,
  organisationSearchFilterObject,
  municipalityList,
  placeHolder,
  heading,
}) => {
  const [doSearch] = useSearchEngine('FilterOrganisationSearchDocument');

  const isLoading = useAppSelector(selectIsLoading);
  const searchParameters = new URLSearchParams(window.location.search);
  const searchField = useRef<HTMLInputElement>(null);

  const multiSelectRef = useRef<SelectInstance<IOption, true, GroupBase<IOption>>>(null);
  const opningsTimeRef: React.RefObject<HTMLInputElement>[] = organisationSearchFilterObject.Clinics.map(() =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRef<HTMLInputElement>(null)
  );
  const clinicsTypesRef: React.RefObject<HTMLInputElement>[] = organisationSearchFilterObject.CareTypes.map(() =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRef<HTMLInputElement>(null)
  );

  const [forceUpdate, setForceUpdate] = useState(false);

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (!isPost) return;
    e.preventDefault();
    const searchString = getCommonSearchParams();
    doSearch(searchString);
  };

  const getCommonSearchParams = (optionalParam = undefined) => {
    const searchParams = new URLSearchParams(optionalParam == undefined ? searchParameters : optionalParam);
    // Lägg till organisationsnamnet om det inte finns
    const searchBase = searchParams.get('searchBase');
    if (searchBase == undefined || searchBase == null) {
      searchParams.append('searchBase', 'ou=' + organisationSearchFilterObject.OrganizationName);
      searchParams.append('searchBase', 'ou=Org');
      searchParams.append('searchBase', 'o=VGR');
    }

    // Lägg till hits parameter om det inte finns
    const hits = searchParams.get('hits');
    if (hits == undefined || hits == null) searchParams.append('hits', '99999');

    // Få alla befintliga "filter"-nycklar och deras värden
    const existingFilters = Array.from(searchParams.entries()).filter(([key]) => key === 'filter');

    // Lägg till filter parameter som har värdet vgrCareType, om det inte finns
    const matchingCareTypeFilter = existingFilters.find(([, value]) => value === 'vgrCareType:01');
    if (matchingCareTypeFilter == undefined) searchParams.append('filter', 'vgrCareType:01');

    // Lägg till filter parameter som har värdet hsaManagementCode, om det inte finns
    const matchingManagementCodeFilter = existingFilters.find(([, value]) => value === 'hsaManagementCode:1');
    if (matchingManagementCodeFilter == undefined) searchParams.append('filter', 'hsaManagementCode:1');

    // Lägg till ett nytt filter som innehåller serviceParameters om det inte finns.
    if (
      organisationSearchFilterObject.ServiceParameters != undefined &&
      organisationSearchFilterObject.ServiceParameters.length > 0
    ) {
      const serviceParams = organisationSearchFilterObject.ServiceParameters.replace('||', '&');
      const serviceParamsValue = serviceParams.split('=')[1]; // om serviceParams har ett sådant värde: filter=...:03 så behöver vi ta bort filter.

      const existing = searchParams.get('serviceParameters'); // Om det är första gången så ska filtret ha nykel serviceParameters
      const matchingServiceParamsFilter = existingFilters.find(([, value]) => value === serviceParamsValue); // om det är inte första gången så skall nykel vara filter.

      // Om det är första gången så behöver vi skriva om serviceParameters till filter.
      if (existing != undefined && existing != null) {
        // Ta bort befintlig sökparameter med nyckeln "serviceParameters"
        searchParams.delete('serviceParameters');

        // Lägg till en ny sökparameter med nyckel 'filter' och dess värde.
        searchParams.append('filter', serviceParamsValue);
      } else if (matchingServiceParamsFilter == undefined || matchingServiceParamsFilter == null)
        // Om serviceParameters och filter finns inte i query så behöver vi lägga in det.
        searchParams.append('filter', serviceParamsValue);
    }

    // Lägg till query parameter om det inte finns annars uppdatera värdet.
    const q = searchParams.get('q');
    if (q != undefined && q != null)
      searchParams.set('q', searchField.current != null ? searchField.current.value : '*');
    else searchParams.append('q', searchField.current != null ? searchField.current.value : '*');

    removeEmptyParams(searchParams);
    return searchParams.toString();
  };

  const handleCheckboxInteraction = (
    checkbox: CheckBox,
    filterKey: MyEnum,
    isChecked: boolean,
    index: number,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    // Använd ref för att snabbt uppdatera checkboxens status
    if (opningsTimeRef[index] != undefined && opningsTimeRef[index] == ref) {
      opningsTimeRef[index].current.checked = isChecked;
      opningsTimeRef[index].current.value = isChecked.toString();
    }
    if (clinicsTypesRef[index] != undefined && clinicsTypesRef[index] == ref) {
      clinicsTypesRef[index].current.checked = isChecked;
      clinicsTypesRef[index].current.value = isChecked.toString();
    }
    const commonFilter = getCommonSearchParams();
    const commonSearchParams = new URLSearchParams(commonFilter);
    checkbox.IsChecked = isChecked;

    if (filterKey === MyEnum.clinicTypes) {
      updateFilterParam(ParamKey.KeyForClinicTypes, FilterKey.NameForClinicTypes, checkbox, commonSearchParams);
    } else {
      if (checkbox.IsChecked) {
        commonSearchParams.set(checkbox.Value, 'true'); // Andra filter, t.ex. Öppettider
      } else {
        commonSearchParams.delete(checkbox.Value);
      }
    }
    // Konvertera URLSearchParams-objektet till en sträng
    let searchString = commonSearchParams.toString();
    searchString = removeEmptyParams(searchString);

    if (!isPost) {
      // Uppdatera URL:en med de nya sökparametrarna
      const newUrl = `${window.location.pathname}?${searchString}`;
      window.history.replaceState({}, '', newUrl);
      setForceUpdate(true);
    }
    if (isPost) doSearch(searchString); // Gör sökningen med de applicerade filter
  };

  const removeEmptyParams = (optionalParam = undefined) => {
    const searchParams = new URLSearchParams(optionalParam == undefined ? searchParameters.toString() : optionalParam);

    for (const [paramKey, paramValue] of searchParams) {
      if (!paramValue || paramValue.trim() === '') {
        searchParams.delete(paramKey, paramValue);
      }
    }
    return searchParams.toString();
  };
  const updateFilterParam = (paramKey: string, filterKey: string, item: CheckBox, commonFilter: URLSearchParams) => {
    let checkedItemValue = commonFilter.getAll(paramKey);
    if (checkedItemValue.length == 0) {
      const paramList = Array.from(commonFilter.entries()).filter(([key]) => key === 'filter'); // hämta alla filter value
      if (paramList.find(([, value]) => value.startsWith(filterKey) != undefined))
        // kolla om någon av filtervalue börjar med filterkey
        checkedItemValue = paramList.find(([, value]) => value.startsWith(filterKey));
    }

    const newItemValue = item.Value.replace('|', ',');

    if (item.IsChecked) {
      // Om checkboxen är ikryssad, lägg till eller uppdatera värden i paramKey
      if (checkedItemValue !== undefined && checkedItemValue != null && checkedItemValue.length > 0) {
        // Om det redan finns värden i paramKey, lägg till det nya värdet
        if (checkedItemValue.find((x) => x != newItemValue)) {
          commonFilter.set(
            paramKey,
            (!checkedItemValue.find((x) => x.startsWith(filterKey)) ? filterKey : '') + // Lägg till filterkey i värdet om den inte finns med.
              checkedItemValue +
              (!checkedItemValue.find((x) => x.endsWith(':')) ? ',' : '') + // Om det är inte första värde som läggs till på samma nykel då betyder det att den inte slutar på ett : då lägger vi till ett komma tecken.
              newItemValue
          );
        }
      } else {
        // Om paramKey är tomt, lägg till det nya värdet direkt
        commonFilter.append(paramKey, filterKey + newItemValue);
      }
    } else {
      // Om checkboxen inte är ikryssad, ta bort den från paramKey
      if (checkedItemValue !== undefined && checkedItemValue !== null && checkedItemValue.length > 0) {
        const values = [];
        checkedItemValue.forEach((x) => values.push(x.replace(filterKey, ''))); // Lägg till alla värde i en array förutom filterkey

        const updatedFilterValues =
          values[0] != undefined
            ? values
                .toString()
                .split(',')
                .filter((value) => !newItemValue.includes(value)) // Ta bort den nya värdet från arrayen
                .join(',')
            : '';

        if (updatedFilterValues.length > 0) {
          // Om det finns kvar några värden, uppdatera paramKey
          commonFilter.set(paramKey, filterKey + updatedFilterValues);
        } else {
          // Om paramKey blir tomt, ta bort hela parametern
          commonFilter.delete(paramKey);
        }
      }
    }
    return commonFilter;
  };
  const clearAllFilters = () => {
    const commonFilter = getCommonSearchParams(new URLSearchParams());
    const commonSearchParams = new URLSearchParams(commonFilter);
    const currentSearchParams = new URLSearchParams(decodeURIComponent(window.location.search));

    const keysToKeep = [];
    // Create a new URLSearchParams object to store the parameters to keep
    const updatedSearchParams = new URLSearchParams();

    // Iterate over the existing parameters
    currentSearchParams.forEach((value, key) => {
      // Check if commonSearchParams has the key
      if (commonSearchParams.has(key)) {
        // Get all values for the common key
        const commonValues = commonSearchParams.getAll(key);

        // Check if the current value is in commonValues
        if (commonValues.includes(value)) {
          // If yes, add the key to keep
          keysToKeep.push(key);
        }
      }

      // Handle specific cases for certain keys
      if (key === 'serviceParameters') {
        keysToKeep.push(key);
        key = 'filter';
        value = value.replace('filter=', '');

        // Add the key and all values from commonSearchParams
        const commonValues = commonSearchParams.getAll(key);
        commonValues.forEach((commonValue) => {
          updatedSearchParams.append(key, commonValue);
        });
      }
    });

    // Add the parameters to keep to the updatedSearchParams
    keysToKeep.forEach((key) => {
      const values = commonSearchParams.getAll(key);
      values.forEach((commonValue) => {
        const filteredKeysSearchParams = new URLSearchParams(
          Array.from(updatedSearchParams.keys())
            .filter((key1) => key1 === key)
            .map((key) => [key, updatedSearchParams.get(key)])
        );

        if (
          filteredKeysSearchParams == undefined ||
          filteredKeysSearchParams.size == 0 || // om nykel inte finns med i updatedsearchparam då lägger vi in det
          (filteredKeysSearchParams != undefined &&
            !Array.from(updatedSearchParams.values()).some((value) => value === commonValue)) // om nykel finns med men har inte samma värde då lägger vi också in det.
        )
          updatedSearchParams.append(key, commonValue);
      });
    });

    const searchString = updatedSearchParams.toString();

    clinicsTypesRef.map((ref) => {
      if (ref.current) ref.current.checked = false;
    });

    opningsTimeRef.map((i) => {
      if (i.current) {
        i.current.value = false.toString();
        i.current.checked = false;
      }
    });
    multiSelectRef.current.clearValue();

    doSearch(searchString); // Gör en sökning med grundläggande filter.
  };

  return (
    <div className={isLoading ? ' loading ' : ''}>
      <section className="search-control-block">
        <div>
          <h2>{heading}</h2>
        </div>
        <form
          method="get"
          className="grid"
          id="search-form"
          action={resultPageLinkAsString}
          onSubmit={(e) => onSearchSubmit(e)}>
          <div className="g-col-12">
            <div className="search-control-block__search-box-button-container">
              <label className="visually-hidden" id="q">
                Fritext för jobbsök
              </label>
              <div className="search-control-block__search-input-autocomplete-wrapper">
                <input
                  type="search"
                  name="q"
                  id="SearchQuery"
                  className="search-control-block__searchQuery"
                  placeholder={placeHolder}
                  autoComplete="off"
                  defaultValue={
                    organisationSearchFilterObject.SearchQuery.length > 0
                      ? organisationSearchFilterObject.SearchQuery
                      : ''
                  }
                  data-organizationname={organisationSearchFilterObject.OrganizationName}
                  aria-autocomplete="both"
                  aria-owns="autocomplete-result"
                  aria-describedby="autocomplete-result"
                  data-querycompletionstring={organisationSearchFilterObject.QueryCompletionString}
                  data-fullquerycompletionresultstring={organisationSearchFilterObject.FullQueryCompletionResultString}
                  ref={searchField}></input>

                <div id="autocomplete-result" className="search-control-block__autocomplete-result d-none"></div>
                <a className="search-control-block__skip-link" href="#scroll-container">
                  Gå direkt till träfflistan
                </a>
              </div>

              <button id="SearchButton" type="submit">
                <span>Sök mottagning</span>
              </button>
            </div>
            <input type="hidden" className="visually-hidde" value="true" name="isSearch"></input>

            <input
              type="hidden"
              className="visually-hidden"
              id="filter-vgrCareType"
              name="filter"
              value={'vgrCareType:01'}
            />
            <input
              type="hidden"
              className="visually-hidde"
              id="filter-hsaManegementCode"
              name="filter"
              value={'hsaManagementCode:1'}
            />

            <input type="hidden" className="visually-hidde" id="hits" name="hits" value={'99999'} />

            <input
              type="hidden"
              id="serviceParameters"
              name="serviceParameters"
              className="visually-hidde"
              value={organisationSearchFilterObject.ServiceParameters}
            />
            <input
              type="hidden"
              id="searchBase"
              className="visually-hidden"
              name="searchBase"
              value={'ou=' + organisationSearchFilterObject.OrganizationName + '&searchBase=ou=Org&searchBase=o=VGR'}
            />
            <details className="expandable-filter" id="expandable-filter-1319850">
              <summary className="link-focus search-control-block__expand-link">
                <span>Visa filtrering</span>
                <span dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></span>
              </summary>
              <Filtering
                isLoading={isLoading}
                organisationSearchFilterObject={organisationSearchFilterObject}
                checkboxInteracted={handleCheckboxInteraction}
                removeEmptyParams={removeEmptyParams}
                isPost={isPost}
                municipalityList={municipalityList}
                searchParameters={searchParameters}
                multiSelectRef={multiSelectRef}
                opningsTimeRef={opningsTimeRef}
                clinicsTypesRef={clinicsTypesRef}
                setForceUpdate={setForceUpdate}></Filtering>

              <div className="g-col-12">
                <button onClick={() => clearAllFilters()} type="reset" disabled={isLoading}>
                  <span dangerouslySetInnerHTML={{ __html: crossIconSvg }}></span>
                  Rensa filter
                </button>
              </div>
              <div data-minimize="" className="search-control-block__minimize">
                <button type="button" className="link-focus" aria-controls="expandable-filter-1319850">
                  Dölj filtrering
                  <span dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></span>
                </button>
              </div>
            </details>
          </div>
        </form>
      </section>
    </div>
  );
};
