import React, { FormEvent, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearchEngine } from '../hooks';
import { ActiveSearchFilters } from './ActiveSearchFilters';
import { useAppDispatch, useAppSelector } from '../store/store.hooks';
import { clearActiveFilters, selectIsLoading, selectSearchResult } from '../store/shared.reducer';
import { Facet } from '../shared.models';

export const SearchHeader: React.VFC = () => {
  /*********************************
   * Variables
   *********************************/
  const isLoading = useAppSelector(selectIsLoading);
  const searchResult = useAppSelector(selectSearchResult);
  const dispatch = useAppDispatch();
  const searchField = useRef<HTMLInputElement>(null);
  const scopeSelect = useRef<HTMLSelectElement>(null);
  const [doSearch] = useSearchEngine('FilterStyrandeDokument');
  const [searchParams] = useSearchParams();
  const queryString = searchResult?.query.queryString;
  const searchFieldDefaultValue = queryString === '*' ? '' : queryString;
  const searchResultQueryForvaltning = new URLSearchParams(searchResult.query.parameters).get('forvaltning');

  const scopeOptions: Facet[] = [];
  searchResult.orgFacets.forEach((facet) => {
    const query = new URLSearchParams(facet.query).get('forvaltning') || searchResultQueryForvaltning || '';
    const option = { ...facet, query };
    scopeOptions.push(option);
  });

  /*********************************
   * Functions
   *********************************/
  function onSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // Delete potential offset to reset pagination to page 1
    searchParams.delete('offset');
    searchParams.set('q', searchField.current.value || '*');

    // If we change scope, cleanup some params
    const scope = scopeSelect.current.value;
    if (scope !== searchResultQueryForvaltning) {
      searchParams.set('forvaltning', scope);
      searchParams.delete('org_path');
      dispatch(clearActiveFilters());
    }

    doSearch(searchParams);
  }

  const goToSearchResultList = () => {
    const searchResultList = document.getElementById('searchResultList');
    searchResultList.focus();
  };

  /*********************************
   * Render
   *********************************/
  return (
    <section
      id="searchHeader"
      aria-label="Sektion med sökfält, aktiva filter och sökningens avgränsning"
      className="vgr-searchservice__head">
      <form method="get" action="" onSubmit={(e) => onSearchSubmit(e)}>
        <div className="vgr-searchservice__searchframe">
          <label className="sr-only" htmlFor="main-search-input">
            Ange sökord
          </label>
          <input
            name="q"
            id="main-search-input"
            type="search"
            placeholder="Sökord"
            autoComplete="off"
            defaultValue={searchFieldDefaultValue}
            ref={searchField}
            disabled={isLoading}
          />
          <label className="sr-only" htmlFor="scope-select">
            Välj sökscope
          </label>
          <div className="select-btn-wrapper">
            <div className="select-wrapper">
              <select
                ref={scopeSelect}
                id="scope-select"
                name={'forvaltning'}
                disabled={isLoading}
                defaultValue={scopeOptions.find((x) => x.selected)?.query}>
                {scopeOptions.map((x) => {
                  return (
                    <option key={`scope_option_${x.displayName}`} value={x.query}>
                      {x.displayName}
                    </option>
                  );
                })}
              </select>
              {chevron}
            </div>
            <button disabled={isLoading} type="submit">
              {searchIcon} Sök
            </button>
            <button onClick={goToSearchResultList} type="button" className="hits-shortcut sr-only">
              Gå direkt till träfflistan
            </button>
          </div>
        </div>
        {searchResult.externalServices && searchResult.externalServices.length > 0 && (
          <nav className="vgr-searchservice__external-services">
            {searchResult.externalServices.map((service, i, arr) => (
              <span key={`exs_${service.displayName}`}>
                <a href={service.url}>{service.displayName}</a>
                {arr.length > 1 && i < arr.length - 1 && <span className="divider">|</span>}
              </span>
            ))}
          </nav>
        )}
        <ActiveSearchFilters />
      </form>
    </section>
  );
};

const chevron = (
  <svg aria-hidden={true} style={{ width: '16px', height: '16px' }}>
    <use xlinkHref="#streamline-arrow"></use>
  </svg>
);

const searchIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3133 18.8028C19.4081 17.0625 21.3168 12.3323 19.5766 8.23759C17.8363 4.14283 13.1061 2.23411 9.01138 3.97434C4.91662 5.71457 3.0079 10.4448 4.74813 14.5395C6.48836 18.6343 11.2186 20.543 15.3133 18.8028Z"
      stroke="white"
      strokeOpacity="0.99"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8584 17.0844L24.1064 23.3333"
      stroke="white"
      strokeOpacity="0.99"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
