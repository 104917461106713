import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../store/store.hooks';
import { fetchSearchData, SetEndpoint } from '../store/shared.reducer';

export function useSearchEngine(endpoint: string) {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const doSearch = useCallback(
    (query: string | URLSearchParams) => {
      SetEndpoint(endpoint)
      dispatch(fetchSearchData(query.toString())).then(() => setSearchParams(query));
      console.log(...getFormatedConsoleString(decodeURIComponent(query.toString())));
    },
    [dispatch, setSearchParams]
  );

  return [doSearch];
}

function getFormatedConsoleString(query: string) {
  const yellowText = 'background: #202124; color: #FFFF00; font-size: 16px;';
  const whiteText = 'background: #202124; color: #FFFFFF; font-size: 13px;';
  let formatedString = query;
  const urlParam = new URLSearchParams(query);
  const keys = [...new Set([...urlParam.keys()])];
  const keyLength = [...urlParam.keys()].length;
  keys.forEach((key) => {
    formatedString = formatedString.replaceAll(key, `%c${key}%c`);
  });
  const formats = [];
  for (let i = 0; i < keyLength * 2; i++) {
    formats.push(i % 2 ? whiteText : yellowText);
  }
  formatedString = 'DISPATCHING SEARCH 🔎:\n' + formatedString;
  return [formatedString, ...formats];
}
