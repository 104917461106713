import React, { useState } from 'react';
import { AddEditKeyMatch } from './types';
import Select from 'react-select';

export const AddEdit = (props: AddEditKeyMatch) => {
  const [selectedScopes, setSelectedScopes] = useState([]);
  const options = props.AllScopes;

  const defaultScopeValues = (x: string) => {
    return { Text: x, Value: x };
  };

  const [stateProperties, setStateProperties] = useState({
    Titel: props.keyMatch.Titel,
    Terms: props.keyMatch.Terms,
    SelectedScope:
      props.keyMatch.SelectedScope != ''
        ? props.keyMatch.SelectedScope.split(',').map((x) => defaultScopeValues(x))
        : null,
    Url: props.keyMatch.Url,
    Description: props.keyMatch.Description,
    Email: props.keyMatch.Email,
  });

  function handleTextBoxChanges(evt) {
    const value = evt.target.value;
    setStateProperties({ ...stateProperties, [evt.target.name]: value });
  }
  function selectOnChange(data: []) {
    console.log('on change = ' + JSON.stringify(data));
    setSelectedScopes(data);
    stateProperties.SelectedScope = data;
  }
  return (
    <form action={props.actionUrl} method="post" className="vgr-form">
      <input type="hidden" name="isIframe" id="isIframe" value={props.isIframe}></input>
      <input type="hidden" name="Active" id="Active" value={props.keyMatch.Active.toString()}></input>
      <input type="hidden" name="Id" id="Id" value={props.keyMatch.Id.toString()}></input>
      <input type="hidden" name="created_at" id="created_at" value={props.keyMatch.created_at}></input>
      <input type="hidden" name="updated_at" id="updated_at" value={props.keyMatch.updated_at}></input>
      <input
        type="hidden"
        name="SelectedScope"
        id="SelectedScope"
        value={
          selectedScopes != null && selectedScopes.length > 0
            ? selectedScopes.map((x) => x.Value).join(',')
            : stateProperties.SelectedScope != null
            ? stateProperties.SelectedScope.map((x) => x.Value).join(',')
            : []
        }></input>
      <div className="vgr-form__item">
        <label htmlFor="Titel" className="vgr-form__label">
          Titel*
        </label>
        <input
          className="vgr-form__input"
          id="Titel"
          name="Titel"
          value={stateProperties.Titel}
          onChange={handleTextBoxChanges}
          placeholder='T.ex. "Alla utbildningar i Västra Götaland"'
          type="text"></input>
        <span className="field-validation-valid vgr-form__helper" data-valmsg-for="Titel" data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>

      <div className="vgr-form__item">
        <label htmlFor="Terms" className="vgr-form__label">
          Nyckelord*
        </label>
        <input
          className="vgr-form__input"
          id="Terms"
          name="Terms"
          data-val="true"
          value={stateProperties.Terms}
          onChange={handleTextBoxChanges}
          data-val-required="The Terms field is required."
          placeholder='Använd bara gemener. Ange en kommaseparerad lista av nyckelord, t.ex. "skola, studera"'
          type="text"></input>
        <span className="field-validation-valid vgr-form__helper" data-valmsg-for="Terms" data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>

      <div className="vgr-form__item">
        <label htmlFor="SelectedScope" className="vgr-form__label">
          Scope*
        </label>

        <Select
          isMulti={true}
          closeMenuOnSelect={false}
          name="SelectedScope"
          options={options != null ? options : []}
          onChange={selectOnChange}
          value={
            stateProperties.SelectedScope != null
              ? stateProperties.SelectedScope
              : selectedScopes != null
              ? selectedScopes
              : []
          }
          getOptionLabel={(option) => (option.Value != '' ? option.Text + ' (' + option.Value + ')' : option.Text)}
          getOptionValue={(option) => option}
        />

        <span
          className="field-validation-valid vgr-form__helper"
          data-valmsg-for="SelectedScope"
          data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>

      <div className="vgr-form__item">
        <label htmlFor="Url" className="vgr-form__label">
          Url*
        </label>
        <input
          className="vgr-form__input"
          id="Url"
          name="Url"
          value={stateProperties.Url}
          onChange={handleTextBoxChanges}
          data-val="true"
          data-val-required="The Url field is required."
          placeholder='T.ex. "http://www.exempel.se/utbildningar"'
          type="text"></input>
        <span className="field-validation-valid vgr-form__helper" data-valmsg-for="Url" data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>

      <div className="vgr-form__item">
        <label htmlFor="Description" className="vgr-form__label">
          Beskrivning*
        </label>
        <textarea
          className="vgr-form__textarea"
          id="Description"
          name="Description"
          data-val="true"
          value={stateProperties.Description}
          onChange={handleTextBoxChanges}
          data-val-required="The Description field is required."
          placeholder='T.ex. "Här hittar du alla utbildningar i Västra Götaland"'></textarea>
        <span
          className="field-validation-valid vgr-form__helper"
          data-valmsg-for="Description"
          data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>

      <div className="vgr-form__item">
        <label htmlFor="Email" className="vgr-form__label">
          E-post*
        </label>
        <input
          className="vgr-form__input"
          id="Email"
          name="Email"
          data-val="true"
          value={stateProperties.Email}
          onChange={handleTextBoxChanges}
          data-val-email="The Email field is not a valid e-mail address."
          data-val-required="The Email field is required."
          placeholder='Ange din epostadress, t.ex. "test.testsson@vgregion.se"'
          type="text"></input>
        <span className="field-validation-valid vgr-form__helper" data-valmsg-for="Email" data-valmsg-replace="false">
          Fältet är obligatoriskt
        </span>
      </div>
      <input type="submit" value="Spara" className="vgr-matchbutton"></input>
      <div className="align-right">
        <a className="vgr-matchbutton" href="/sponsrade-sidor/">
          Tillbaka
        </a>
      </div>
    </form>
  );
};
