import React from 'react';
import { KivOpeningHours, OrganisationSearchItem } from './models';

interface Props {
  item: OrganisationSearchItem;
  chevronIconSvg: string;
}

export const OrganisationSearchResultItem: React.VFC<Props> = ({ item, chevronIconSvg }) => {
  const title = item.title;
  const daysOfWeek = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];

  // Funktion för att kontrollera om det finns någon dag som kommer öppnas inom 24 timmar
  function isOpeningWithin24Hours(schedule: KivOpeningHours[]): { day: string; openTime: Date } | null {
    const now = new Date();
    const next24Hours = new Date();
    next24Hours.setHours(now.getHours() + 24);
    const opningHours = GetOpeningHours(schedule);
    for (const opningHour of opningHours) {
      if (opningHour != null) {
        const openTime = opningHour.openTime;
        // Check if the day will open within 24 hours
        if (openTime > now && openTime <= next24Hours) {
          return { day: opningHour.day, openTime };
        }
      }
    }

    return null;
  }
  function openingsTime(item): string {
    const today = new Date(); // Skapa ett datumobjekt för idag
    const closeTime = new Date(`2000-01-01T${item.today.close}`); // Skapa ett datumobjekt för stängningstiden
    let formattedCloseTime = '';

    if (closeTime.getMinutes() === 0) {
      formattedCloseTime = closeTime.getHours().toLocaleString('sv-SE', { minimumIntegerDigits: 2 });
    } else {
      formattedCloseTime = closeTime.toLocaleTimeString('sv-SE', {
        hour: '2-digit',
        minute: '2-digit',
      });
    }
    return formattedCloseTime;
  }
  function openingsZoonTime(openingSoon): string {
    const openTime = openingSoon.openTime;

    let formattedTime = '';

    if (!isNaN(openTime)) {
      const date = new Date(`2000-01-01T${openTime.toLocaleTimeString('sv-SE')}`);

      if (date.getMinutes() === 0) {
        formattedTime = date.getHours().toString();
      } else {
        formattedTime = date.toLocaleTimeString('sv-SE', {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    }
    return formattedTime;
  }
  function GetOpeningHours(schedule: KivOpeningHours[]): { day: string; openTime: Date }[] | null {
    const result: { day: string; openTime: Date }[] = [];

    for (const day of schedule) {
      const date = new Date();
      date.setDate(date.getDate() + ((day.day - date.getDay() + 7) % 7));
      const dateString = date.toISOString().split('T')[0];

      // Parse the open and close times and create Date objects
      const openTimeString = `${dateString}T${day.open}`;
      const openTime = new Date(openTimeString);

      // Translate day number to the corresponding day name
      const dayName = daysOfWeek[day.day];

      // Add the object to the result array
      result.push({ day: dayName, openTime: openTime });
    }

    if (result.length > 0) {
      return result;
    } else {
      return null;
    }
  }
  const openingHours =
    item.openingHoursPerDay != undefined && item.openingHoursPerDay != null
      ? GetOpeningHours(item.openingHoursPerDay)
      : null;
  const openingSoon =
    item.openingHoursPerDay != null && item.openingHoursPerDay != undefined
      ? isOpeningWithin24Hours(item.openingHoursPerDay)
      : null;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const closestDay =
    openingHours != undefined && openingHours != null
      ? openingHours.reduce((closest, current) => {
          const currentDate = current.openTime;
          return Math.abs(currentDate.getTime() - today.getTime()) <
            Math.abs(closest.openTime.getTime() - today.getTime())
            ? current
            : closest;
        }, openingHours[0])
      : null;

  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ...
  let index = 0;

  const addSpacesToPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) {
      return '';
    }

    const numberParts = phoneNumber.split('-');

    if (numberParts.length < 2) {
      return phoneNumber;
    }

    const trimmedNumberParts = numberParts.map((numberPart) => numberPart.trim());

    if (trimmedNumberParts[1].length === 6) {
      const numbersSplit = [
        trimmedNumberParts[1].substring(0, 2),
        trimmedNumberParts[1].substring(2, 4),
        trimmedNumberParts[1].substring(4, 6),
      ];
      return trimmedNumberParts[0] + ' - ' + numbersSplit.join(' ');
    }

    if (trimmedNumberParts[1].length === 7) {
      const numbersSplit = [
        trimmedNumberParts[1].substring(0, 3),
        trimmedNumberParts[1].substring(3, 5),
        trimmedNumberParts[1].substring(5, 7),
      ];
      return trimmedNumberParts[0] + ' - ' + numbersSplit.join(' ');
    }

    if (trimmedNumberParts[1].length === 8) {
      const numbersSplit = [
        trimmedNumberParts[1].substring(0, 3),
        trimmedNumberParts[1].substring(3, 6),
        trimmedNumberParts[1].substring(6, 8),
      ];
      return trimmedNumberParts[0] + ' - ' + numbersSplit.join(' ');
    }

    return phoneNumber;
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) {
      return '';
    }

    if (phoneNumber === '1177') {
      return phoneNumber;
    }

    let formattedNumber = '';

    if (phoneNumber.startsWith(' ')) {
      phoneNumber = phoneNumber.trimStart();
    }

    if (phoneNumber.startsWith('+46')) {
      formattedNumber = phoneNumber.substring(3);
    } else if (phoneNumber.startsWith('0')) {
      formattedNumber = phoneNumber.substring(1);
    } else {
      formattedNumber = phoneNumber;
    }

    switch (formattedNumber[0]) {
      case '1':
        formattedNumber = `${formattedNumber.substring(0, 2)} - ${formattedNumber.substring(2)}`;
        break;
      case '2':
        formattedNumber =
          formattedNumber[1] === '0' && formattedNumber[2] === '0'
            ? `${formattedNumber.substring(0, 3)} - ${formattedNumber.substring(3)}`
            : `${formattedNumber.substring(0, 2)} - ${formattedNumber.substring(2)}`;
        break;
      case '3':
        formattedNumber = ['1', '3', '6'].includes(formattedNumber[1])
          ? `${formattedNumber.substring(0, 2)} - ${formattedNumber.substring(2)}`
          : `${formattedNumber.substring(0, 3)} - ${formattedNumber.substring(3)}`;
        break;
      case '5':
        formattedNumber =
          formattedNumber[1] === '4'
            ? `${formattedNumber.substring(0, 2)} - ${formattedNumber.substring(2)}`
            : `${formattedNumber.substring(0, 3)} - ${formattedNumber.substring(3)}`;
        break;
      case '7':
        formattedNumber = `${formattedNumber.substring(0, 2)} - ${formattedNumber.substring(2)}`;
        break;
      case '8':
        formattedNumber = `${formattedNumber.substring(0, 1)} - ${formattedNumber.substring(1)}`;
        break;
      case '9':
        formattedNumber = `${formattedNumber.substring(0, 3)} - ${formattedNumber.substring(3)}`;
        break;
      default:
        break;
    }
    return addSpacesToPhoneNumber('0' + formattedNumber);
  };

  return (
    <li className="g-col-12">
      <article data-id={item.id}>
        <header className="badge">
          <div className="search-result-block__heading-meta-container">
            <div className="badge__content-wrapper">
              <a
                className="ignore-readability-rule"
                href={item.url != undefined && item.url.length > 0 ? item.url : ''}>
                <h3 className="search-result-block__heading" dangerouslySetInnerHTML={{ __html: title }} />
              </a>

              {item.openingHoursPerDay != null &&
              item.openingHoursPerDay.filter((x) => x.isToday === true && x.isOpen == true) != undefined &&
              item.openingHoursPerDay.filter((x) => x.isToday === true && x.isOpen == true).length > 0 ? (
                <div className="badge__shape badge__shape--success">
                  <>{`Öppet till ${openingsTime(item)}`}</>
                </div>
              ) : openingSoon != undefined && openingSoon != null ? (
                <div
                  key={`${openingSoon.openTime.toLocaleTimeString('sv-SE')} ${openingSoon.day}`}
                  className="badge__shape">
                  {'Öppnar ' + openingsZoonTime(openingSoon)}
                </div>
              ) : (
                item.openingHoursPerDay != null &&
                item.openingHoursPerDay != undefined && (
                  <div className="badge__shape badge__shape--danger">{'Stängt'}</div>
                )
              )}
            </div>
            <div className="search-result-block__sub-heading ignore-readability-rule">
              {item.streetaddress != undefined && item.streetaddress.split('$')[0] != undefined && (
                <span>{item.streetaddress.split('$')[0]}</span>
              )}
              {item.streetaddress != undefined && item.streetaddress.split('$')[2] && (
                <>
                  {' '}
                  &#x2022; <span> {item.streetaddress.split('$')[2]}</span>
                </>
              )}
              {item.openingHoursPerDay != null &&
              item.openingHoursPerDay.filter((x) => x.isToday === true && x.isOpen == true) != undefined &&
              item.openingHoursPerDay.filter((x) => x.isToday === true && x.isOpen == true).length > 0 ? (
                <>
                  {' '}
                  &#x2022;{' '}
                  <span>
                    {' '}
                    {`Öppet till ${
                      !isNaN(new Date(new Date(`2000-01-01T${item.today.close}`)).getTime())
                        ? new Date(`2000-01-01T${item.today.close}`).toLocaleTimeString('sv-SE', {
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : ''
                    }`}
                  </span>
                </>
              ) : openingSoon != undefined && openingSoon != null ? (
                <>
                  {' '}
                  &#x2022;{' '}
                  <span key={item.id + openingSoon.day + index++}>
                    {'Öppnar ' +
                      (!isNaN(new Date(`2000-01-01T${openingSoon.openTime.toLocaleTimeString('sv-SE')}`).getTime())
                        ? new Date(`2000-01-01T${openingSoon.openTime.toLocaleTimeString('sv-SE')}`).toLocaleTimeString(
                            'sv-SE',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )
                        : '')}
                  </span>
                </>
              ) : closestDay != undefined && closestDay != null ? (
                <>
                  {' '}
                  &#x2022;{' '}
                  <span key={item.id + closestDay.day + index++}>
                    {'Öppnar ' +
                      closestDay.day +
                      ' ' +
                      (!isNaN(new Date(`2000-01-01T${closestDay.openTime.toLocaleTimeString('sv-SE')}`).getTime())
                        ? new Date(`2000-01-01T${closestDay.openTime.toLocaleTimeString('sv-SE')}`).toLocaleTimeString(
                            'sv-SE',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )
                        : '')}
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </header>
        <div className="search-result-block__body">
          <details className="search-result-block__detail">
            <summary className="link-focus">
              <span className="details-title-show">Visa mer uppgifter</span>
              <span className="details-title-hide">Dölj mer uppgifter</span>
              <span className="visually-hidden">om {item.title}</span>
              <div dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></div>
            </summary>
            <div className="search-result-block__details-wrapper">
              <div className="search-result-block__details-content search-result-block__details-content">
                {item.streetaddress && (
                  <div className="search-result-block__meta-group">
                    <div>
                      <b>Address</b>
                      <div>
                        <span>{item.streetaddress.split('$')[0]}</span>
                      </div>
                      <div>
                        <span>{item.streetaddress.split('$')[1] + ' ' + item.streetaddress.split('$')[2]}</span>
                      </div>
                    </div>
                  </div>
                )}

                {item.phonenumber && (
                  <div className="search-result-block__meta-group">
                    <div>
                      <b>Telefonnummer</b>
                      <div>
                        <a href={`tel:${formatPhoneNumber(item.phonenumber[0])}`}>
                          {formatPhoneNumber(item.phonenumber[0])}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {item.openingHoursPerDay && (
                <>
                  <div className="search-result-block__details-content search-result-block__details-content">
                    <div className="search-result-block__meta-group search-result-block__meta-smallerGroup">
                      <b>Öppettider</b>
                      {item.openingHoursPerDay.map((x) => {
                        // Get the name of the day using the day of the week
                        const dayName = daysOfWeek[x.day];
                        // Convert the first letter to uppercase
                        const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

                        // Convert opening and closing times to Date objects
                        const openingTime = new Date(`2000-01-01T${x.open}`);
                        const closingTime = new Date(`2000-01-01T${x.close}`);

                        // Check if openingTime is a valid Date object
                        const formattedOpen = !isNaN(openingTime.getTime())
                          ? openingTime.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })
                          : '';

                        // Check if closingTime is a valid Date object
                        const formattedClose = !isNaN(closingTime.getTime())
                          ? closingTime.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })
                          : '';

                        return (
                          <div key={item.id + capitalizedDayName?.toString() + index++}>
                            {x.day === dayOfWeek ? <strong>{capitalizedDayName}:</strong> : <>{capitalizedDayName}:</>}{' '}
                            <span>
                              {x.day === dayOfWeek ? (
                                <strong>
                                  {formattedOpen && formattedClose ? (
                                    <>
                                      {String(formattedOpen)} - {String(formattedClose)}
                                    </>
                                  ) : (
                                    'Invalid opening or closing time'
                                  )}
                                </strong>
                              ) : (
                                <>
                                  {formattedOpen && formattedClose ? (
                                    <>
                                      {String(formattedOpen)} - {String(formattedClose)}
                                    </>
                                  ) : (
                                    'Invalid opening or closing time'
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              <div className="search-result-block__details-content search-result-block__details-content--nomargin">
                <div className="search-result-block__meta-group">
                  <div>
                    <b>Karta</b>
                    <div>
                      <a href="#" className="map-link">
                        Visa på karta
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>
      </article>
    </li>
  );
};
