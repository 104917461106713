import { Facet } from "../shared/shared.models";

export interface OrganisationSearchItem {
  id: string;
  title: string;
  url:string;
  streetaddress: string;
  coordinates: KivDataGeoCoordinates;
  geodist:string;
  phonenumber: string[];
  isOpen: boolean;
  IsClosingSoon:boolean;
  today: Today;
  openingHoursPerDay: KivOpeningHours[];
  opening_hours:KivOpeningHours[];
}
export interface OrganisationSearchFilterObject{
  Clinics:CheckBox[];
  CareTypes: CheckBox[];
  MunicipalityList:Facet[];
  HideMunicipality:boolean;
  HideClinics:boolean;
  HideCareType:boolean;
  SearchQuery:string;
  CoordsX:string;
  CoordsY:string;
  OrganizationName:string;
  ServiceParameters :string;
  QueryCompletionServiceParameters: string;
  QueryCompletionString : string;
  FullQueryCompletionResultString:string;
  ResultPageId :number;
  myEnum:MyEnum;
}
export interface SelectListItem{
  Text:string;
  Value:string;
  Selected:boolean;
}
export interface CheckBox {
  Value:string;
  Text: string;
  IsChecked:boolean;
}
export interface KivOpeningHours{
  isToday:boolean;
  open: Date;
  close:Date;
  day:  number;
  isOpen:boolean;
}
export interface Today {
  close:string
}
export interface KivAddress{
  streetaddress: string;
  Postcode:string;
  Municipality:string;
}
export interface KivDataGeoCoordinates {
  coordinatesX:string;
  coordinatesY:string;
}

export interface SearchResponse<TDocumentType> {
  components:components<TDocumentType>; 
  municipalityList: SelectListItem[];
}
export interface components<TDocumentType>
{
  doclists:TDocumentType;
  sortOptions:sortOptions[]
}
export interface doclists<TDocumentType>{
  documents:TDocumentType[];
  hits:number;
}


export interface sortOptions {
  params:Params[];
  displayName: string;
  applied: boolean;
  query:string;
}
export interface Params{
  name:string;
  values: string[];
}



// Deklaration av enum
export enum MyEnum {
  'oppeningHours',
  'clinicTypes',
  'municipality'
}

const ParamKey = {
  KeyForClinicTypes: 'filter4',
  KeyForMunicipality: 'filter5',
};

const FilterKey = {
  NameForClinicTypes: 'hsaBusinessClassificationCode:',
  NameForMunicipality: 'hsaMunicipalityCode:',
};

export  { ParamKey, FilterKey };