import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { enablePolyfills } from '../shared/shared.polyfills';
import { store } from '../shared/store/shared.store';
import { Provider } from 'react-redux';
import { OrganisationSearchDocumentDataSetup } from './OrganisationSearchDocumentDataSetup';

interface Props {
  searchResponse: string;
  chevronIconSvg: string;
  initialQuery: string;
  isPost: string;
}
export const OrganisationSearchDocumentApp: React.VFC<Props> = ({
  searchResponse,
  chevronIconSvg,
  initialQuery,
  isPost,
}) => {
  const SSR = typeof window === 'undefined';
  if (!SSR) enablePolyfills();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('vgr-searchservice__html');
  }, []);

  return (
    <Provider store={store}>
      {!SSR && (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <OrganisationSearchDocumentDataSetup
                  searchResponse={searchResponse}
                  chevronIconSvg={chevronIconSvg}
                  initialQuery={initialQuery}
                  isPost={isPost}
                />
              }></Route>
          </Routes>
        </BrowserRouter>
      )}
      {SSR && (
        <StaticRouter location="/">
          <Routes>
            <Route
              path="*"
              element={
                <OrganisationSearchDocumentDataSetup
                  searchResponse={searchResponse}
                  chevronIconSvg={chevronIconSvg}
                  initialQuery={initialQuery}
                  isPost={isPost}
                />
              }></Route>
          </Routes>
        </StaticRouter>
      )}
    </Provider>
  );
};
