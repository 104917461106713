import React, { useRef, useEffect, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import { ImageSliderProps, Slide } from './types';

export const ImageSlider: React.VFC<ImageSliderProps> = ({ slides, totalNumberOfSlides, sliderWidth }) => {
  let mainRef = useRef<Splide>();
  let thumbsRef = useRef<Splide>();
  const thumbnailWidth = 128;
  const thumbnailHeight = 72;
  const [arrowOffsetTop, setArrowOffsetTop] = useState(0);

  function updateSize() {
    /* 
    1) Goal:      The arrows should be placed in the middle of the image - even when the screen size is changed.
      
    2) Problem:   The next/previous arrows are by default placed on in the middle of the slide,
                  but the height of the slide may vary depending on how long the slide text is.

    3) Solution:  We can use the slide width to calculate the image height.
                  The aspect ratio of the image should always be 16/9.
                  With this information we can calculate the image height.
    */
    const aspectRatio = 16.0 / 9.0;
    const imageHeight = mainRef.current.splideRef.current.clientWidth / aspectRatio;
    setArrowOffsetTop(imageHeight / 2);
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide);
    }

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const sharedOptions: Options = {
    arrowPath:
      'M46.7274 11.8719C47.8088 12.9597 47.8088 14.7236 46.7274 15.8115L26.938 35.7186C26.5524 36.1071 26.0944 36.4153 25.5903 36.6256C25.0861 36.8358 24.5457 36.9441 24 36.9441C23.4543 36.9441 22.9139 36.8358 22.4097 36.6256C21.9056 36.4153 21.4476 36.1071 21.062 35.7186L1.27262 15.8115C0.191166 14.7236 0.191166 12.9597 1.27262 11.8719C2.35406 10.784 4.10745 10.784 5.1889 11.8719L24 30.7949L42.8111 11.8719C43.8925 10.784 45.6459 10.784 46.7274 11.8719Z',
    i18n: {
      prev: 'Förgående bild',
      next: 'Nästa bild',
      first: 'Gå till första bilden',
      last: 'Gå till sista bilden',
      slideX: 'Gå till bild %s',
      pageX: 'Gå till sida %s',
      select: 'Välj en bild att visa',
      carousel: `bildspel`,
      slide: 'bild',
      slideLabel: '%s av %s',
    },
  };

  const mainOptions: Options = {
    ...sharedOptions,
    type: 'loop',
    label: `Bildspel med ${totalNumberOfSlides} bilder`,
    perPage: 1,
    perMove: 1,
    pagination: false,
    width: `${sliderWidth}px`,
    classes: {
      arrow: 'splide__arrow image-slider__arrow image-slider__arrow--main-slide',
      prev: 'splide__arrow--prev image-slider__arrow--previous',
      next: 'splide__arrow--next image-slider__arrow--next',
    },
  };

  const thumbsOptions: Options = {
    ...sharedOptions,
    type: 'slide',
    label: `Bildspel med ${totalNumberOfSlides} bilder. Miniatyrbilder för bildspelet. Val av miniatyrbild ändrar bild i det primära bildspelet.`,
    rewind: true,
    gap: '1rem',
    pagination: false,
    fixedWidth: thumbnailWidth,
    fixedHeight: thumbnailHeight,
    cover: true,
    isNavigation: true,
    classes: {
      arrow: 'splide__arrow image-slider__arrow',
      prev: 'splide__arrow--prev image-slider__arrow--previous',
      next: 'splide__arrow--next image-slider__arrow--next',
    },
  };

  const mainSlideContent = (slide: Slide) => {
    return (
      <div className="image-slider__slide">
        <div dangerouslySetInnerHTML={{ __html: slide.imageHtml }}></div>
        <div>
          <span>
            <b>
              {slide.index + 1} av {totalNumberOfSlides}
            </b>
          </span>
        </div>
        <div className="image-slider__text" dangerouslySetInnerHTML={{ __html: slide.textHtml }}></div>
      </div>
    );
  };

  return (
    <div className="image-slider">
      <noscript>
        {/* Splide doesn't work automatically for us when js is turned off. */}
        {slides.map((slide) => (
          <div key={slide.index}>{mainSlideContent(slide)}</div>
        ))}
      </noscript>
      <Splide hasTrack={false} options={mainOptions} ref={mainRef}>
        <SplideTrack>
          {slides.map((slide) => (
            <SplideSlide key={slide.index}>{mainSlideContent(slide)}</SplideSlide>
          ))}
        </SplideTrack>
        <div
          className="splide__arrows"
          style={{ '--arrow-offset-top': arrowOffsetTop + 'px' } as React.CSSProperties}></div>
      </Splide>
      <div className="image-slider__thumbnails">
        <Splide options={thumbsOptions} ref={thumbsRef}>
          {slides.map((slide) => (
            <SplideSlide key={slide.index}>
              {/* The thumbnail size need to be twice as large as it's available space to ensure a good image quality with ImageResizer */}
              <img
                src={slide.imageUrl + `?w=${thumbnailWidth * 2}&h=${thumbnailHeight * 2}&mode=crop&anchor=topcenter`}
                alt={slide.imageAltText}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
